<script lang="ts" setup>
import { computed } from 'vue'
import RoomieTag from '@lahaus-roomie/roomie/src/components/RoomieTag/index.vue'
import UnlockIcon from '@lahaus-roomie/roomie/src/assets/icons/unlock.svg'
import type { Props } from './types'
import ImageShowcase from '@/components/ImageShowcase/index.vue'
import { getDeliveryTermLabel, getBedroomsLabel } from '@/utils/project'
import { useUserStore } from '@/stores/userStore'
import './_index.scss'

const userStore = useUserStore()

const isContentLocked = computed(() => !userStore.isAuthenticated)

const props = withDefaults(defineProps<Props>(), {
  blank: true,
  isBrochure: false,
  exactBedrooms: null
})
const emit = defineEmits(['card-click'])

const { t, n } = useI18n()

const pictureParams = () => {
  if (props.flexibleCard) return '?auto=compress,enhance,format&w=600&h=750&fit=crop'

  return '?auto=compress,enhance,format&w=500&h=500&ar=1:1'
}

const coverPicture = {
  src: `${props.project.cover}${pictureParams()}`,
  alt: t('coverOf', { name: props.project.name })
}

const route = useRoute()
const router = useRouter()

const goToProject = (url: string) => {
  emit('card-click')

  if (props.preventRedirect) return

  let urlRecommendedProject = ''
  if (props.isBrochure) {
    const { turnScheduledId } = route.params
    const publicPath = (turnScheduledId && turnScheduledId === 'public') ? '/public' : ''
    const slugRecommendedProject = props.project.path.split('/')[3]
    const params = new URLSearchParams(route.query).toString()
    const queryParams = params ? `?${params}` : ''
    urlRecommendedProject = `/brochure/${slugRecommendedProject}${publicPath}${queryParams}`
  }
  const targetUrl = props.isBrochure ? urlRecommendedProject : url

  if (props.blank) {
    return navigateTo(targetUrl, {
      open: {
        target: '_blank'
      }
    })
  }

  router.push(targetUrl)
}

const images = computed(() => {
  const filteredPictures = props.project.pictures_v2?.filter(picture => picture.type === 'gallery') || []

  const picturesList = filteredPictures.map(picture => ({
    src: `${picture.url}${pictureParams()}`,
    alt: t('coverOf', { name: props.project.name })
  }))

  return [coverPicture, ...picturesList].slice(0, 5)
})

const getBedroomsNumber = () => {
  if (props.exactBedrooms !== null) return t('project.bedrooms.exact', { n: props.exactBedrooms })
  else return getBedroomsLabel(props.project.typologies || [], t)
}

const minPrice = computed(() => {
  if (props.project.typologies) {
    const [cheapestTypologyPrice] = props.project.typologies.map(typology => typology.price).sort((a, b) => a - b)

    if (!cheapestTypologyPrice) return props.project.price_label

    return n(cheapestTypologyPrice, 'currency')
  }

  return props.project.price_label
})

const tag = computed(() => {
  return `${getDeliveryTermLabel(props.project.delivered_at, t)} · ${getBedroomsNumber()}`
})

const sanitizedPath = computed(() => {
  return props.project.path && (props.project.path.startsWith('/') || props.project.path.startsWith('http'))
    ? props.project.path
    : '#'
})

const resolveCityLabelName = (cityLabel: string) => {
  return cityLabel.toLowerCase() === t('longCity')
    ? t('shortCity')
    : cityLabel
}
</script>

<script lang="ts">
export default {
  name: 'InvestmentProjectCard'
}
</script>

<template>
  <div
    class="investment-project-card"
    :class="{ 'investment-project-card--flexible': flexibleCard }"
    :data-lh-id="`investment-project-card-${site}`">
    <RoomieTag
      :id="`investment-project-card-${project.code}`"
      class="investment-project-card__tag"
      variant="success"
      :label="tag" />

    <ImageShowcase
      class="investment-project-card__background"
      :images="images"
      :lazy-load="pictureLazyLoad"
      :data-lh-id="`investment-project-card-${site}`"
      @click="goToProject(project.path)" />

    <div
      class="investment-project-card__container"
      :data-lh-id="`investment-project-card-${site}`"
      @click="goToProject(project.path)">
      <div class="investment-project-card__shadow pointer-events-none" />

      <div class="investment-project-card__content pointer-events-none relative">
        <div
          v-if="isContentLocked"
          class="investment-project-card__lock absolute top-0 left-0 w-full px-24 flex gap-10 items-center">
          <div class="border border-white rounded-circle w-32 h-32 flex justify-center items-center shrink-0">
            <UnlockIcon class="fill-white w-24 h-24" />
          </div>

          <p v-sanitize="t('unlock-cta')" />
        </div>

        <div>
          <a :href="sanitizedPath">
            <h3 class="text-brand-300 text-20 font-medium truncate">
              {{ project.name }}
            </h3>
          </a>

          <p class="text-18 truncate">
            {{ project.neighborhood_label }}, {{ resolveCityLabelName(project.city_label) }}
          </p>
        </div>

        <div class="mt-14 flex justify-between text-14">
          <p
            :class="{ '2xl:text-16': flexibleCard }">
            {{ t('priceFrom') }}
          </p>

          <p
            :class="{ '2xl:text-18': flexibleCard }"
            class="text-16">
            {{ minPrice }} {{ project.currency }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n src="./i18n.json" lang="json"></i18n>
